import bg_collaboration from '../assets/images/bg-collaboration.jpg';
import bg_design from '../assets/images/bg-design.jpg';
import bg_part from '../assets/images/bg-part.jpg';
import bg_api from '../assets/images/bg-api.jpg';
import bg_repo from '../assets/images/bg-repo.jpg';
import bg_ops from '../assets/images/bg-ops.jpg';
import bg_open_source from '../assets/images/bg-open-source.jpg';
import bg_free from '../assets/images/bg-free.jpg';
import bg_vscode from '../assets/images/bg-vscode.jpg';
import bg_cli from '../assets/images/bg-cli.jpg';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';

function Home() {
  const car_img_style = {
    // "maxHeight": "400px",
    // "minHeight": "400px",
    height: "25rem",
    objectFit: "cover",
  };

  const style_caption = {
      textShadow: "-4px -4px 8px #000, 4px 4px 8px #000, 4px -4px 8px #000, -4px 4px 8px #000",
      textColor: "#fff",
      color: "#fff",
      };

  const style_container = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
    paddingTop: 20,
  };
  const style_card = {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 5,
    marginRight: 5,
    // width: '18rem',
    // border: "none",
    height: "calc(100% - 10px)",
    display: "flex" ,
    flexDirection: "column",
  };
  const style_body = {
    padding: 10,
    // padding: "25px",
    // backgroundColor: "#282c34",
    textAlign: "center",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };
  const style_image = {
    paddingBottom: 10,
    // paddingBottom: "20px",
    // flex: "auto",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: 10,
    fontWeight: 1200,
    flex: "none",
  };
  const style_link = {
    paddingTop: 10,
    // display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
    fontWeight: 200,
    // textDecoration: "none",
    flex: "none",
    fontStyle: "italic",
  };

  return (
    <div className="App-view">
 <Container>
      <Carousel style={{ marginBottom: "40px" }}>
        <Carousel.Item bg="dark">
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_collaboration}
            alt="State-Of-The-Art Collaboration"
          />
          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>State-Of-The-Art Collaboration</h3>
            <p>
              Collaborate on your designs using the industry standard tool: Git
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item bg="dark">
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_design}
            alt="Publish Designs"
          />
          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Publish Designs</h3>
            <p>
              Provide your users with assembly instructions, bill of materials,
              and an easy way to purchase or to manufacture all the parts.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_part}
            alt="Advertise Parts"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Advertise Parts</h3>
            <p>
              Publish your parts so that others can use these parts in their assemblies.
              Provide your users with ordering and manufacturing instructions.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_api}
            alt="Reach More Customers"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Reach More Customers</h3>
            <p>
              Enable the use of your API for additive and subtractive manufacturing
              of corresponding parts in some assemblies.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_repo}
            alt="Simplify Development"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Simplify Development</h3>
            <p>
              Optionally, use a private repository to enable your developers
              to collaborate on assembly instructions effectively.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_ops}
            alt="Simplify Operations"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Simplify Operations</h3>
            <p>
              Share your product&apos;s assembly instructions
              with contract manufacturing providers to reduce the overhead.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_open_source}
            alt="Open Platform"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Open Platform</h3>
            <p>
              Open source (Apache 2.0) platform for the top innovation speed.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_free}
            alt="Free Tools"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Free Tools</h3>
            <p>
              No subsbcription or per-seat costs. No one time payments. None.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_vscode}
            alt="VS Code Extension"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>VS Code Extension</h3>
            <p>
              Use our Visual Studio Code extension to develop your blueprints.<br />
              Enjoy the user experience that powers most of development workflows worldwide.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg_cli}
            alt="Command Line Tools"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Command Line Tools</h3>
            <p>
              Use our CLI tools for automation or hardcore development experiences.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </Container>

        <Row lg={12} style={{width: "80%"}}>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                What is PartCAD?
              </Card.Title>
              <Card.Body>
                <Link to="https://github.com/openvmp/partcad/">PartCAD</Link> is the standard for documenting manufacturable physical products. It comes with a set of tools to maintain
                product information and to facilitate efficient and effective workflows at all product lifecycle phases.

                <br/><br/>
                PartCAD is more than just a traditional CAD tool for drawing. In fact, it’s <b>not for drawing at all</b>. The letters
                “CAD” in PartCAD stand for “computer-aided design” in a more generic sense, where “design” stands for the process of
                getting from an idea to <b> clear and deterministic specification of a manufacturable physical product</b> using a
                computer (including the use of AI models). While PartCAD started as <b>the first package manager for hardware</b>, it is
                now <b>the next-generation CAD</b> that can turn a single visionary individual into a one person corporation, or make one
                future Product Manager as productive (**and much faster!**) as 10 corporate engineering departments of the past.

                <br/><br/>
                PartCAD is constantly evolving, with new features and integrations being added all the time.&nbsp;
                <b><Link to="https://calendly.com/partcad-support/30min">Contact us</Link> to discuss how PartCAD can revolutionize your product development process.</b>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                PartCAD packages
              </Card.Title>
              <Card.Body>
                <Link to="https://github.com/openvmp/partcad/">PartCAD</Link> includes tools to package product information:
                <br/><br/>
                <ul>
                <li>Optional (but highly recommended) <b>high-level requirements</b> <i>(texts and drawings)</i></li>
                <li>Optional <b>detailed design</b> <i>(mechanical outline, PCB schematics, software architecture)</i></li>
                <li>Implementation <i>(<b>mechanical CAD files, PCB layout, software artifacts</b>)</i></li>
                <li>Optionally, the following data can be provided to augment or complement the output:</li>

                <li><ul>
                  <li>Additional manufacturing process requirements and instructions</li>
                  <li>Additional product validation instructions</li>
                  <li><b>Maintenance instructions</b></li>
                </ul></li>
                <li>Or any other product related metadata</li>
                </ul>

                Such packages are <b>modular and reusable</b>, allowing one to build not only on top of the CAD files of previous products, but
                to <b>build on top of their manufacturing processes</b> as well.
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                PartCAD outputs
              </Card.Title>
              <Card.Body>
                As a result of maintaining the product information using PartCAD, the following outputs can be generated and, if
                necessary, collected and managed using PartCAD tools:
                <br/><br/>
                <ul>
                <li><b>Product documentation</b> <i>(markdown, html or PDF)</i></li>
                <li>Design validation <b>results</b></li>
                <li>Product <b>bill of materials</b> <i>(mechanical, electrical, software)</i></li>
                <li>Sourcing information <b>for all components</b></li>
                <li>Manufacturing <b>process specification</b> <i>(including required equipment if any)</i></li>
                <li>Manufacturing <b>instructions</b> <i>(sufficiently documented to be reproduced by anyone without inquiring any additional
                  information)</i></li>
                <li>Product <b>validation</b> instructions</li>
                <li>Product validation <b>results</b> <i>(given access to an experimental product and the required tools)</i></li>
                <li>Input data for software components to visualize the product on your website (<i>with a 3D viewer, <b>a configurator,
                  manufacturing/assembly instructions</b> and more)</i></li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                Product development and testing
              </Card.Title>
              <Card.Body>
                Once product information is packaged, it can be versioned and used for iterative improvements or to produce PartCAD
                outputs either by human or AI actors. To achieve that, PartCAD integrates with third-party tools. Below are just some
                examples of what third-party integrations can be used for:
                <br/><br/>
                <ul>
                <li>AI tools can be used to <b>update the mechanical design and implementation automatically</b> based on the current state of the requirements</li>
                <li>A legacy CAD tool can be used manually to update the implementation</li>
                <li>AI tools can be used to validate the design and implementation to identify product requirement or best practices <i>(e.g.
                  to reduce manufacturing complexity)</i> violations</li>
                <li><b>A web interface of an online store or an API of an additive manufacturer</b> can be used to source and manufacture parts</li>
                <li>Simulation tools <i>(potentially in conjunction with AI tools)</i> can be used to validate that the product design matches
                  the product requirements</li>
                <li>AI tools can be used to review the product implementation for <b>correctness, safety or compliance</b></li>
                <li>Manufacturing processes are <b>verified for completeness</b> <i>(e.g. tools requirements are specified for all operations)</i></li>
                <li>Manufacturing instructions are <b>verified for correctness</b> <i>(e.g. the provided manufacturing steps can actually be
                  successfully and safely performed, and fit within the capabilities of the selected manufacturing tools)</i></li>
                </ul>

                Some of the iterative improvements or tests can be achieved using PartCAD built-in features. However, the use of
                third-party tools is <b>recommended for unlocking cutting edge innovations and features</b>.
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                Operations using PartCAD
              </Card.Title>
              <Card.Body>
                PartCAD also works on the following supplementary products to enable (if needed) operations without any use of
                third-party tools:
                <br/><br/>
                <ul>
                <li>A CRM for part manufacturing and assembly shops for businesses of any size <i>(from skilled individuals working in their
                  garage to the biggest factories)</i> to <b>immediately start taking orders</b> for manufacturable products maintained using
                  PartCAD</li>
                <li>An inventory tool to manage the list of parts and final products in stock, as well as to track and manage all
                  in-progress or completed orders, to <b>immediately bring supply chains up and to scale them up while keeping all data
                  private on-prem</b> and not incurring any costs <i>(for cloud services and alike)</i></li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={4}>
            <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title style={{ margin: 15 }}>
                Supply chains based on PartCAD
              </Card.Title>
              <Card.Body>
                By letting the user easily switch between third-party engineering tools or manufacturers without having to migrate
                product data, PartCAD creates a competitive environment for service providers to <b>drive the costs down</b>.
                <br/><br/>
                Whenever you select third-party tools (if any) to use in your workflows, you ultimately decide (and make it transparent
                or auditable) <b>how secure your supply chain is and how exposed your product information is</b>. If you opt for on-prem tools
                only, all your product information remains on-prem too. It makes PartCAD an ultimate solution for achieving data
                sovereignty for those willing to keep their product data private. <b>In the age of cloud data harvesting (especially for AI
                training), it makes PartCAD a better alternative to any cloud-based PDM, PLM or BOM solution.</b>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      <center style={{ width: "80%", marginTop: 20 }}>
        See <Link to="https://partcad.readthedocs.io/">documentation</Link> to learn how to use PartCAD.
      </center>

      <center style={{ width: "80%", marginTop: 40 }}>
        <Button style={{border: "none", color:"black", backgroundColor: '#F5BB2B'}} href="https://calendly.com/partcad-support/30min">Let's chat!</Button>
      </center>
 
      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Open Versatile Mobility Platform (OpenVMP) implements multi-modal mobility mechanisms that perform arbitrary tasks individually or collectively.
          </p>
          <a
            className="App-link"
            href="https://gitbub.com/openvmp/openvmp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Repository
          </a>
        </header>
      </div> */}

      <div className="featured-view">
        Featured assemblies:
        <Row style={{maxWidth: "100%", justifyContent: "center"}}>
        <Col style={{}} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Link className="TreeItem" to={`/repository/assembly/robotics/multimodal/openvmp/robots/don1:robot`}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Title style={style_title}>Don1 is the first OpenVMP "proof-of-concept" robot</Card.Title>
              <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                <Card.Img variant="top" style={style_image} src="/content/robotics/multimodal/openvmp/robots/don1/robot.svg" />
              </div>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col style={{}} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Link className="TreeItem" to={`/repository/assembly/furniture/workspace/basic:imperial-desk-1`}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Title style={style_title}>Basic desk using standard imperial dimensional lumber</Card.Title>
              <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                <Card.Img variant="top" style={style_image} src="/content/furniture/workspace/basic/imperial-desk-1.svg" />
              </div>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        <Col style={{}} xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Link className="TreeItem" to={`/repository/assembly/robotics/multimodal/openvmp/robots/don1:assembly-wormgear`}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Title style={style_title}>Worm gearbox assembly using goBILDA parts</Card.Title>
              <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                <Card.Img variant="top" style={style_image} src="/content/robotics/multimodal/openvmp/robots/don1/assembly-wormgear.svg" />
              </div>
            </Card.Body>
          </Card>
          </Link>
        </Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
