import { Alert, Card, Tab, Tabs } from "react-bootstrap";
import Tree, { FilteringContainer, renderers } from 'react-virtualized-tree'
import 'react-virtualized/styles.css';
import 'react-virtualized-tree/lib/main.css';
import logo from '../assets/images/logo.png';
import React, { Component } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import content from '../assets/content.json';
import 'material-icons/css/material-icons.css';
import { Col, Row, Container, Button } from 'react-bootstrap';
import Part from './Part';

const { Expandable, Favorite } = renderers;

// const EXPANDED = 'EXPANDED';

const style_container = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
  // width: '18rem',
};
const style_card = {
  // width: '18rem',
  // border: "none",
  // marginBottom: 10,
  marginLeft: 5,
  marginRight: 5,
  // height: "calc(100% - 10px) !important",
  height: "calc(100% - 10px)",
  display: "flex" ,
  flexDirection: "column",
};
const style_body = {
  padding: 10,
  // backgroundColor: "#282c34",
  textAlign: "center",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  containerType: "inline-size",
};
const style_image = {
  paddingBottom: 10,
  // width: "128px",
  // width: "inherit",
  // maxHeight: "calc(15vh)",
  // minHeight: "calc(var(--variable-width) + 20px) !important",
  //maxHeight: "10px !important",
  // maxHeight: "calc(100cqh)",
  maxHeight: "calc(150cqw)",
  // flex: "auto",
  // alignSelf: "center",
  // justifySelf: "center",
};
const style_title = {
  fontSize: 16,
  color: '#ccc',
    paddingBottom: 10,
  fontWeight: 1200,
  // textDecoration: "none",
  flex: "none",
};
const style_link = {
  paddingTop: 10,
  // display: 'block',
  fontSize: 16,
  color: '#ccc',
  fontWeight: 200,
  // textDecoration: "none",
  flex: "none",
  fontStyle: "italic",
};

class Repository extends Component {
  static KIND_PACKAGE = "package";
  static KIND_PART = "part";
  static KIND_ASSEMBLY = "assembly";

  static isFavorite(path, name) {
    if (path.indexOf("openvmp") !== -1) {
      return true;
    }
    if (path.indexOf("/std/") !== -1) {
      return true;
    }
    if (name.indexOf("imperial") !== -1) {
      return true;
    }
    return false;
  }

  static part_from_content(path, part_name, part, parent) {
    const node = {
      kind: Repository.KIND_PART,
      id: path + ":" + part_name,
      package: path,
      packageUrl: parent.url || undefined,
      name: part_name,
      desc: part.desc,
      cover: part.cover,
      model: part.model,
      url: part.url || undefined,
      children: [],
      state: {
        expanded: false,
        favorite: Repository.isFavorite(path, part_name),
        deletable: false,
      },
    };
    return node;
  }
  static assembly_from_content(path, assembly_name, assembly, parent) {
    const node = {
      kind: Repository.KIND_ASSEMBLY,
      id: path + ":" + assembly_name,
      package: path,
      packageUrl: parent.url || undefined,
      name: assembly_name,
      desc: assembly.desc,
      cover: assembly.cover,
      model: assembly.model,
      url: assembly.url || undefined,
      children: [],
      state: {
        expanded: false,
        favorite: Repository.isFavorite(path, assembly_name),
        deletable: false,
      },
    };
    return node;
  }
  static node_from_content(item) {
    const node = {
      kind: Repository.KIND_PACKAGE,
      id: item.name,
      name: item.name.substring(item.name.lastIndexOf("/") + 1),
      desc: item.desc,
      cover: item.cover,
      children: [],
      state: {
        expanded: false,
        favorite: false,
        deletable: false,
      },
    };
    for (const child_name in item['children']) {
      node.children.push(Repository.node_from_content(item["children"][child_name]));
    }
    for (const part_name in item['parts']) {
      node.children.push(Repository.part_from_content(item["name"], part_name, item["parts"][part_name], item));
    }
    for (const assembly_name in item['assemblies']) {
      node.children.push(Repository.assembly_from_content(item["name"], assembly_name, item["assemblies"][assembly_name], item));
    }
    return node;
  }

  constructor(props) {
    super(props);

    const allNodes = Repository.node_from_content(content);
    // console.log(content);
    // console.log(allNodes.children);

    this.state = {
      nodes: allNodes.children,
      selectedGroup: "ALL",
    };

    //???
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectedGroupChange = this.handleSelectedGroupChange.bind(this);
  }

  get _groupProps() {
    return {
      groups: {
        ALL: {
          name: 'All',
          filter: node => true,
        },
        // [EXPANDED]: {
        //   name: 'Expanded',
        //   filter: node => (node.state || {}).expanded,
        // },
        FAVORITES: {
          name: 'Favorites',
          filter: node => (node.state || {}).favorite,
        },
      },
      selectedGroup: this.state.selectedGroup,
      onSelectedGroupChange: this.handleSelectedGroupChange,
    };
  }

  handleChange(nodes) {
    this.setState({ nodes });
  };

  handleSelectedGroupChange(selectedGroup) {
    this.setState({ selectedGroup });
  };

  static getPackage(nodes, name) {
    let found = undefined;

    for (const node_idx in nodes) {
      const node = nodes[node_idx];

      if (node.kind !== Repository.KIND_PACKAGE) {
        continue;
      }

      node.state.expanded = false;

      if (found === undefined) {
        if (node.id === name) {
          node.state.expanded = true;
          found = node;
        }
      }

      if (found === undefined) {
        found = Repository.getPackage(node.children, name);
        if (found !== undefined) {
          node.state.expanded = true;
        }
      }
    }

    return found;
  }

  static getItem(nodes, path, kind, name) {
    const pkg = Repository.getPackage(nodes, path);
    if (pkg === undefined) {
      return undefined;
    }

    const target = pkg.id + ":" + name;
    for (const node_idx in pkg.children) {
      const node = pkg.children[node_idx];

      // console.log(node);
      // console.log(name);
      if (node.kind !== kind) {
        continue;
      }

      if (node.id === target) {
        return node;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    let { pathname } = props.router.location;
    const { item_kind: state_item_kind, item_name: state_item_name } = state;
    let node = undefined;

    // console.log("location: ", props.router.location);

    if (!pathname.startsWith("/repository")) {
      return null;
    }

    let item_kind = Repository.KIND_PACKAGE;
    let item_name = "/";
    pathname = pathname.substring("/repository/".length);
    if (pathname !== undefined || pathname !== "") {
      const sepPos = pathname.indexOf("/");
      if (sepPos !== -1) {
        item_kind = pathname.substring(0, sepPos);
        item_name = "/" + pathname.substring(sepPos + 1);
      }
    }
    // console.log("kind: ", item_kind);
    // console.log("name: ", item_name);

    if (state_item_kind !== item_kind || state_item_name !== item_name) {
      if (item_kind === Repository.KIND_PACKAGE) {
        node = Repository.getPackage(state.nodes, item_name);
      }
      else {
        const name_parts = item_name.split(":");
        const path = name_parts[0];
        const name = name_parts[1];

        if (item_kind === Repository.KIND_PART) {
          node = Repository.getItem(state.nodes, path, Repository.KIND_PART, name);
        }
        else {
          node = Repository.getItem(state.nodes, path, Repository.KIND_ASSEMBLY, name);
        }
      }
      // console.log("node: ", node);

      return { item_kind, item_name, node };
    }

    return null;
  }

  render() {
    const { item_kind, node, nodes } = this.state;

    let sub_packages = [];
    let parts = [];
    let assemblies = [];
    if (node !== undefined) {
      sub_packages = node.children.filter((child) => (child.kind === Repository.KIND_PACKAGE));
      parts = node.children.filter((child) => (child.kind === Repository.KIND_PART));
      assemblies = node.children.filter((child) => (child.kind === Repository.KIND_ASSEMBLY));
    } else {
      sub_packages = nodes.filter((child) => (child.kind === Repository.KIND_PACKAGE));
    }

    return (
      <div className="App-view">
        <Container className="Columns">
          <Row style={{ width: "100%" }}>
            <Col xs={12} sm={6} md={6} lg={4} xl={4} style={{ marginBottom: 10 }}>
              <FilteringContainer groupRenderer={() => {}} nodes={this.state.nodes}>
                {/* {...this._groupProps}> */}
                {({ nodes }) => (
                  <div className="filteringContainerDiv">
                    <Tree nodes={nodes} onChange={this.handleChange}>
                      {({ style, node, ...rest }) => (
                        <div style={{ ...style, whiteSpace: "nowrap" }}>
                          <Expandable node={node} {...rest}>
                            {/* <Favorite node={node} {...rest}> */}
                              <Link className="TreeItem" to={`/repository/${node.kind}${node.id}`}>{node.name}</Link>
                            {/* </Favorite> */}
                          </Expandable>
                        </div>
                      )}
                    </Tree>
                  </div>
                )}
              </FilteringContainer>
            </Col>
            <Col xs={12} sm={6} md={6} lg={8} xl={8}>
              <Row>
                <>
                  {item_kind === Repository.KIND_PACKAGE &&
                    (
                      <>
                        {sub_packages.length > 0 && (
                          <>
                            <span className='CardGroup'>Sub-packages:</span>
                            {sub_packages.map((child) => (
                              <Col xs={6} sm={6} md={6} lg={4} xl={3}>
                                <Link className="TreeItem" to={`/repository/package${child.id}`}>
                                  <Card bg="dark" style={style_card}>
                                    <Card.Body style={style_body}>
                                      <Card.Title className="CardTitle" style={style_title}>{child.name}</Card.Title>
                                      <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                                        <Card.Img variant="top" style={style_image} src={child.cover ? "/" + child.cover : logo} />
                                      </div>
                                      {child.desc &&
                                        <Card.Text className="CardTitle" style={style_link}>{child.desc}</Card.Text>
                                      }
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </Col>
                            ))}
                          </>
                        )}
                        {assemblies.length > 0 && (
                          <>
                            <span className='CardGroup'>Assemblies:</span>
                            {assemblies.map((child) => (
                              <Col xs={6} sm={6} md={6} lg={4} xl={3}>
                                <Link className="TreeItem" to={`/repository/assembly${child.id}`}>
                                  <Card bg="dark" style={style_card}>
                                    <Card.Body style={style_body}>
                                      <Card.Title className="CardTitle" style={style_title}>{child.name}</Card.Title>
                                      <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                                        <Card.Img variant="top" style={style_image} src={"/" + child.cover} />
                                      </div>
                                      {child.desc &&
                                        <Card.Text className="CardTitle" style={style_link}>{child.desc}</Card.Text>
                                      }
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </Col>
                            ))}
                          </>
                        )}
                        {parts.length > 0 && (
                          <>
                            <span className='CardGroup'>Parts:</span>
                            {parts.map((child) => (
                              <Col xs={6} sm={6} md={6} lg={4} xl={3}>
                                <Link className="TreeItem" to={`/repository/part${child.id}`}>
                                  <Card bg="dark" style={style_card}>
                                    <Card.Body style={style_body}>
                                      <Card.Title className="CardTitle" style={style_title}>{child.name}</Card.Title>
                                      <div style={{flex: 1, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                                        <Card.Img variant="top" style={style_image} src={"/" + child.cover} />
                                      </div>
                                      {child.desc &&
                                        <Card.Text className="CardTitle" style={style_link}>{child.desc}</Card.Text>
                                      }
                                    </Card.Body>
                                  </Card>
                                </Link>
                              </Col>
                            ))}
                          </>
                        )}
                      </>
                    )
                  }
                  {(item_kind === Repository.KIND_PART || item_kind === Repository.KIND_ASSEMBLY) && (
                    <>
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <table style={{ width: "100%" }} cellPadding={5}>
                              <tr>
                                <td width="30%" align="right" valign="top">Package:</td>
                                <td style={{textWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all"}}>{node.packageUrl ?
                                (
                                  <a href={node.packageUrl} rel="nofollow">{"/pub" + node.package}</a>
                                ) : (
                                  "/pub" + node.package
                                )}</td>
                              </tr>
                              <tr>
                                <td width="30%" align="right" valign="top">Name:</td>
                                <td style={{textWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all"}}>{node.name}</td>
                              </tr>
                              {(node.desc !== undefined && node.desc !== null && node.desc.length > 0) && (
                                <tr>
                                  <td width="30%" align="right" valign="top">Desc:</td>
                                  <td style={{textWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all"}}>{node.desc}</td>
                                </tr>
                              )}
                              {(node.url !== undefined && node.desc !== null && node.desc.length > 0) && (
                                <tr>
                                  <td width="30%" align="right" valign="top">URL:</td>
                                  <td style={{textWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all"}}><a href={node.url} rel="nofollow">{node.url}</a></td>
                                </tr>
                              )}
                            </table>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Button disabled style={{border: "none", color:"black", backgroundColor: '#F5BB2B', textAlign: "left", marginLeft: 10, marginBottom: 10}}>Export...</Button>
                            {item_kind === Repository.KIND_ASSEMBLY ? (
                              <>
                                <Button disabled style={{border: "none", color:"black", backgroundColor: '#F5BB2B', textAlign: "left", marginLeft: 10, marginBottom: 10}}>Download assembly<br/>instructions...</Button>
                                <Button disabled style={{border: "none", color:"black", backgroundColor: '#F5BB2B', textAlign: "left", marginLeft: 10, marginBottom: 10}}>Order parts...</Button>
                              </>
                            ) : (
                              <>
                                <Button disabled style={{border: "none", color:"black", backgroundColor: '#F5BB2B', textAlign: "left", marginLeft: 10, marginBottom: 10}}>Order...</Button>
                              </>
                            )}
                      </Col>
                      <Tabs defaultActiveKey="basic">
                        <Tab eventKey="summary" title="Summary" disabled>
                        </Tab>
                        <Tab eventKey="basic" title="CAD Basic" style={{display: "block", height: "100%"}}>
                          <div style={{ display: "block", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                            <Part node={node} />
                          </div>
                        </Tab>
                        <Tab eventKey="detailed" title="CAD Detailed" disabled>
                        </Tab>
                        {(item_kind === Repository.KIND_ASSEMBLY) && (
                          <Tab eventKey="bom" title="BoM" disabled>
                          </Tab>
                        )}
                        {(item_kind === Repository.KIND_PART) && (
                         <Tab eventKey="cae" title="CAE" disabled>
                         </Tab>
                        )}
                        {(item_kind === Repository.KIND_PART) && (
                         <Tab eventKey="cam" title="CAM" disabled>
                         </Tab>
                        )}
                        <Tab eventKey="summary" title="Manufacturability" disabled>
                        </Tab>
                      </Tabs>
                    </>
                  )}
                </>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(Repository);
